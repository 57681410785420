import {Offcanvas, OffcanvasGravity} from "../../../../offcanvas/Offcanvas";
import {useEffect, useState} from "react";
import Rosetta from "../../../../../rosetta/Rosetta";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../../network/API";
import Validator from "../../../../../util/Validator";
import AlertModal from "../../../../alertmodal/AlertModal";
import {Toast} from "../../../../toast/TokyoToaster";

export const DocumentCollectionTypeEditorModal = (props) => {

    const {shown} = props;
    const {typeID} = props;
    const {callback} = props;

    const [documentType, setDocumentType] = useState(null);
    const [typeTitle, setTypeTitle] = useState("");

    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [forceDismiss, setForceDismiss] = useState(false);

    const [callbackData, setCallbackData] = useState(null);

    useEffect(() => {
        if (shown) {
            if (typeID) {
                fetchTypeOverNetwork();
            }
        } else {
            setTypeTitle("");
            setDocumentType(null);
            setForceDismiss(false);
        }
    }, [shown]);

    useEffect(() => {
        if (documentType) {
            setTypeTitle(documentType.documentType);
        }
    }, [documentType]);

    function handleCallback(action) {
        if (callback) {
            callback(action, callbackData);
        }
    }

    function fetchTypeOverNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let data = {
            ids : [typeID],
            page : 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.project.getProjectCollectionDocumentTypes, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setDocumentType(resp.data.data[0]);
                    } else {
                        Toast.show(
                            Rosetta.string("common.error"),
                            Rosetta.string("document_collections.type_editor_not_found"),
                            Toast.ERROR,
                            Toast.LONG
                        );
                        setForceDismiss(true);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PDTF1000C" }));
            });
    }

    function submitTypeOverNetwork() {
        if (networkInFlight) return;

        const validationData = Validator.validateCreateFormData({
            typeID, typeTitle
        }, [
            Validator.rule("typeID", "int", "", "id", true),
            Validator.rule("typeTitle", "string", Rosetta.string("document_collections.type_editor_name"), "documentType")
        ]);

        if (!validationData.success) {
            AlertModal.showError(validationData.error);
            return;
        }

        setNetworkInFlight(true);

        const data = validationData.formData;

        Axios.post(ENDPOINTS.project.submitProjectCollectionDocumentType, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("document_collections.type_editor_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setForceDismiss(true);
                    setCallbackData(resp.data.documentType);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PCDT1000C" }));
            });
    }

    if (!shown) return [];

    return (
        <Offcanvas
            shown={true}
            title={Rosetta.string("document_collections.type_editor_title")}
            gravity={OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("document_collections.type_editor_name")}</label>
                    <input type={"text"} className={"form-control"} value={typeTitle} onChange={(e) => setTypeTitle(e.target.value)} />
                </div>
            </div>

            <div className={"row mt-2"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-primary"} onClick={() => submitTypeOverNetwork()}>{Rosetta.string("common.save")}</button>
                </div>
            </div>

        </Offcanvas>

    )

}