import FormEditorComponent from "./form/FormEditorComponent";
import {useParams} from "react-router-dom";
import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../../rosetta/Rosetta";

export const FormEditorScreen = (props) => {

    const {formID} = useParams();

    return (
        <div className={"app-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("form_editor.editor_title")} />
                </div>
            </div>

            <FormEditorComponent formID={formID} />
        </div>
    )

}