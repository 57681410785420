import "./FormInputPhotoSelectorComponent.css";
import {CommonUtil} from "../../../../../../util/CommonUtil";

export const FormInputPhotoSelectorComponent = (props) => {

    const {input} = props;

    let formItems = [];

    for (let i = 0; i < 30; i++) {
        formItems.push(
            <div className={"image-dummy"} />
        )
    }

    return (
        <div className={"form-input-photo-selector-component"}>
            <label>{CommonUtil.getOrDefault(input, "label", "")}</label>
            <div className={"image-container"}>
                {formItems}
            </div>
        </div>
    )

}