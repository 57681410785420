import {Offcanvas, OffcanvasActions, OffcanvasGravity} from "../../../../offcanvas/Offcanvas";
import Rosetta from "../../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {FileComponent} from "../../../common/FileComponent";
import {LoadingSpinner} from "../../../../loading/LoadingSpinner";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../../network/API";
import AlertModal from "../../../../alertmodal/AlertModal";
import {ProjectSelectionModal} from "../../../common/ProjectSelectionModal";
import WindowUtil from "../../../../../util/WindowUtil";
import {UrlUtil} from "../../../../../util/UrlUtil";
import Validator from "../../../../../util/Validator";
import {Toast} from "../../../../toast/TokyoToaster";

export const DocumentCollectionEditorModal = (props) => {

    const {shown} = props;
    const {documentID} = props;
    const {typeID} = props;
    const {callback} = props;

    const [document, setDocument] = useState(null);
    const [documentProject, setDocumentProject] = useState(null);
    const [documentTitle, setDocumentTitle] = useState("");
    const [documentFile, setDocumentFile] = useState(null);

    const [callbackData, setCallbackData] = useState(null);

    const [documentNetworkInFlight, setDocumentNetworkInFlight] = useState(false);

    const [projectSelectionShown, setProjectSelectionShown] = useState(false);

    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();

            if (documentID) {
                fetchDocumentFromNetwork();
            }
        } else {
            WindowUtil.unlockBodyScroll();

            setDocument(null);
            setDocumentTitle("");
            setDocumentProject(null);
            setDocumentFile(null);

            setForceDismiss(false);
        }
    }, [shown]);

    useEffect(() => {
        if (!projectSelectionShown) {
            if (shown) {
                WindowUtil.lockBodyScroll();
            }
        }
    }, [projectSelectionShown]);

    useEffect(() => {
        if (document) {
            setDocumentProject({ id : document.projectID, project_name : document.projectName })
            setDocumentTitle(document.notes)
        }
    }, [document]);

    function handleCallback(action) {
        if (callback) {
            callback(action, callbackData);
        }
    }

    function projectSelectionDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                setDocumentProject(data);
            }
            setProjectSelectionShown(false);
        }
    }

    function fetchDocumentFromNetwork() {
        if (documentNetworkInFlight) return;
        setDocumentNetworkInFlight(true);

        let data = {
            ids : [documentID]
        };

        Axios.post(ENDPOINTS.project.getProjectCollectionDocuments, data)
            .then((r) => {
                let error = null;

                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setDocument(resp.data.data[0]);
                    } else {
                        error = "document_collections.document_editor_not_found";
                    }
                } else {
                    error = API.formatError(resp);
                }

                if (error !== null) {
                    AlertModal.showError(Rosetta.string(error));
                }

                setDocumentNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setDocumentNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PDE1000C" }));
            });
    }

    function submitDocumentOverNetwork() {
        if (documentNetworkInFlight) return;

        const validationResult = Validator.validateCreateFormData({
            documentID, typeID, documentTitle,
            projectID : (documentProject ? documentProject.id : undefined)
        }, [
            Validator.rule("documentID", "int", "", "id", true),
            Validator.rule("typeID", "int", "", "documentTypeID"),
            Validator.rule("projectID", "int", Rosetta.string("document_collections.document_editor_project"), "projectID"),
            Validator.rule("documentTitle", "string", Rosetta.string("document_collections.document_editor_notes"), "notes")
        ]);

        let errorMessage = null;

        if (!validationResult.success) {
            errorMessage = validationResult.error;
        } else if (!documentID && !documentFile) {
            errorMessage = Rosetta.string("validation.missing_property", { label : Rosetta.string("document_collections.document_editor_file")});
        }

        if (errorMessage) {
            AlertModal.showError(errorMessage);
            return;
        }

        setDocumentNetworkInFlight(true);

        const formData = validationResult.formData;

        if (documentFile) {
            formData.append("file", documentFile);
        }

        Axios.post(ENDPOINTS.project.submitProjectCollectionDocument, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("document_collections.document_editor_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                    setCallbackData(resp.data.document);
                    setForceDismiss(true);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setDocumentNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setDocumentNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PDES3001C" }));
            });
    }

    // RENDER

    if (!shown) return [];

    let formActions = (<button className={"btn btn-primary"} onClick={() => submitDocumentOverNetwork()}>{Rosetta.string("common.save")}</button>);
    if (documentNetworkInFlight) {
        formActions = (
            <LoadingSpinner small={true} inline={true} />
        );
    }

    let existingFileCard = [];
    if (document && document.filePath) {
        existingFileCard = (
            <div className={"row mt-2"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("document_collections.document_editor_file_existing")}</label>
                    <div className={"card"}>
                        <div className={"card-body"}>
                            <div className={"row align-items-center"}>
                                <div className={"col-md-8"}>
                                    {UrlUtil.getLastPathPart(document.filePath)}
                                </div>
                                <div className={"col-md-4 text-end"}>
                                    <a className={"btn btn-primary"} href={document.filePath} target={"_blank"} rel={"noreferrer"}>{Rosetta.string("common.view")}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Offcanvas
                shown={true}
                gravity={OffcanvasGravity.END}
                title={Rosetta.string("document_collections.document_editor_title")}
                forceDismiss={forceDismiss}
                callback={handleCallback}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("document_collections.document_editor_project")}</label>
                        <span className={"form-control clickable"} onClick={() => setProjectSelectionShown(true)}>{documentProject ? documentProject.project_name : Rosetta.string("common.please_select")}</span>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("document_collections.document_editor_notes")}</label>
                        <textarea className={"form-control"} value={documentTitle} onChange={(e) => setDocumentTitle(e.target.value)} />
                    </div>
                </div>

                {existingFileCard}

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("document_collections.document_editor_file")}</label>
                        <FileComponent callback={setDocumentFile} />
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        {formActions}
                    </div>
                </div>

            </Offcanvas>

            <ProjectSelectionModal
                shown={projectSelectionShown}
                callback={projectSelectionDidCallback} />
        </>
    )

}