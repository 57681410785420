import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {TimeUtil} from "../../../../util/TimeUtil";
import {useParams} from "react-router-dom";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import AlertModal from "../../../alertmodal/AlertModal";
import {OffcanvasActions} from "../../../offcanvas/Offcanvas";
import {FormSelectionModal} from "./FormSelectionModal";
import Validator from "../../../../util/Validator";
import {ProjectSelectionModal} from "../../common/ProjectSelectionModal";
import {Toast} from "../../../toast/TokyoToaster";

export const ProjectSubmittableEditorScreen = (props) => {

    const {id} = useParams();

    const [title, setTitle] = useState();
    const [projectID, setProjectID] = useState();
    const [projectName, setProjectName] = useState();
    const [description, setDescription] = useState();
    const [formTypeID, setFormTypeID] = useState();
    const [validityDuration, setValidityDuration] = useState();
    const [formID, setFormID] = useState();
    const [formName, setFormName] = useState();

    const [validityDurationNumber, setValidityDurationNumber] = useState(0);
    const [validityDurationUnit, setValidityDurationUnit] = useState(TimeUtil.DAY);

    const [submittable, setSubmittable] = useState(null);
    const [submittableNetworkInFlight, setSubmittableNetworkInFlight] = useState(false);

    const [formTypes, setFormTypes] = useState([]);
    const [formTypeNetworkInFlight, setFormTypeNetworkInFlight] = useState(false);

    const [projectSelectionShown, setProjectSelectionShown] = useState(false);
    const [formSelectionShown, setformSelectionShown] = useState(false);

    const [submitNetworkInFlight, setSubmitNetworkInFlight] = useState(false);

    useEffect(() => {
        if (id && id !== "new") {
            fetchProjectSubmittableFromNetwork();
        }
        fetchFormTypesFromNetwork();
    }, []);

    useEffect(() => {
        if (validityDurationNumber !== "" && validityDurationUnit >= 0) {
            let newValue = parseInt(parseFloat(validityDurationNumber) * parseInt(validityDurationUnit));
            setValidityDuration(newValue);
        } else {
            // TODO Error?
            setValidityDuration(0);
        }
    }, [validityDurationNumber, validityDurationUnit]);

    useEffect(() => {
        if (submittable) {
            setTitle(submittable.title);
            setProjectID(submittable.projectID);
            setProjectName(submittable.projectName);
            setDescription(submittable.description);
            setFormTypeID(submittable.formTypeID);
            setFormID(submittable.formID);
            setFormName(submittable.formName);
            setValidityDuration(submittable.validityDuration);
            setFormTypeID(submittable.projectFormTypeID);

            if (submittable.validityDuration > 0) {
                let units = [TimeUtil.YEAR, TimeUtil.MONTH, TimeUtil.WEEK, TimeUtil.DAY, TimeUtil.HOUR];
                for (let i = 0; i < units.length; i++) {
                    if (submittable.validityDuration > units[i]) {
                        setValidityDurationNumber(submittable.validityDuration / units[i]);
                        setValidityDurationUnit(units[i]);
                        break;
                    }
                }
            }
        }
    }, [submittable]);

    function clientSelectionDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                setProjectID(data.id);
                setProjectName(data.project_name);
            }
            setProjectSelectionShown(false);
        }
    }

    function formSelectionDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                setFormID(data.id);
                setFormName(data.name);
            }
            setformSelectionShown(false);
        }
    }

    function fetchProjectSubmittableFromNetwork() {
        if (submittableNetworkInFlight) return;
        setSubmittableNetworkInFlight(true);

        const data = {
            ids : [id],
            page : 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.projectForm.getProjectSubmittablesAdmin, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.submittables.length > 0) {
                        setSubmittable(resp.data.submittables[0]);
                    }
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmittableNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmittableNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.common_error_unknown", { error_code : "FSE1000C" }));
            });
    }

    function fetchFormTypesFromNetwork() {
        if (formTypeNetworkInFlight) return;
        setFormTypeNetworkInFlight(true);

        Axios.get(ENDPOINTS.projectForm.getProjectFormTypes)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setFormTypes(resp.data.formTypes);
                } else {
                    console.log(API.formatError(resp));
                }
                setFormTypeNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    function submitProjectSubmittableOverNetwork() {
        if (submitNetworkInFlight) return;

        let validationResult = Validator.validateCreateFormData({
            projectID, title, description, formTypeID,
            validityDuration, formID
        }, [
            Validator.rule("projectID", "int", Rosetta.string("projectSubmittable.editor_project"), "projectID", true),
            Validator.rule("title", "string", Rosetta.string("projectSubmittable.editor_title"), "title"),
            Validator.rule("description", "string", "", "description", true),
            Validator.rule("formTypeID", "int", Rosetta.string("projectSubmittable.editor_form_type"), "projectFormTypeID"),
            Validator.rule("validityDuration", "int", Rosetta.string("projectSubmittable.editor_validity_duration"), "validityDuration", true),
            Validator.rule("formID", "int", Rosetta.string("projectSubmittable.editor_form"), "formID")
        ]);

        if (!validationResult.success) {
            AlertModal.showError(validationResult.error);
            return;
        }

        let formData = validationResult.formData;

        if (id && id !== "new") {
            formData.append("id", id);
        }

        setSubmitNetworkInFlight(true);

        Axios.post(ENDPOINTS.projectForm.submitProjectSubmittable, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSubmittable(resp.data.projectSubmittable);

                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("projectSubmittable.submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmitNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmitNetworkInFlight(false);
                AlertModal.showModal(Rosetta.string("common.error_common_unknown", { error_code : "PSS1000C" }));
            });
    }

    return (
        <div className={"app-screen form-submittables-list-screen"}>

            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("projectSubmittable.editor_screen_title")} />
                </div>
            </div>

            <div className={"animate-screen-content"}>

                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-md-10 col-lg-8"}>

                        <div className={"card"}>
                            <div className={"card-body"}>

                                <div className={"row"}>
                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("projectSubmittable.editor_title")}</label>
                                        <input type={"text"} className={"form-control"} value={title} onChange={(e) => setTitle(e.target.value)} />
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("projectSubmittable.editor_project")}</label>
                                        <div className={"form-control clickable"} onClick={() => setProjectSelectionShown(true)}>{projectName ? projectName : Rosetta.string("projectSubmittable.editor_project_none")}</div>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12 mt-2"}>
                                        <label>{Rosetta.string("projectSubmittable.editor_description")}</label>
                                        <textarea className={"form-control"} value={description} onChange={(e) => setDescription(e.target.value)} />
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12 mt-2"}>
                                        <label>{Rosetta.string("projectSubmittable.editor_form")}</label>
                                        <div className={"form-control clickable"} onClick={() => setformSelectionShown(true)}>{formName ? formName : Rosetta.string("common.please_select")}</div>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-12 col-lg-6 mt-2"}>
                                        <label>{Rosetta.string("projectSubmittable.editor_form_type")}</label>
                                        <select className={"form-select"} value={formTypeID} onChange={(e) => setFormTypeID(e.target.value)}>
                                            <option value={"0"}>{Rosetta.string("common.please_select")}</option>
                                            {
                                                formTypes.map((formType) => (
                                                    <option value={formType.id}>{formType.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>

                                    <div className={"col-12 col-md-6 mt-2"}>
                                        <label>{Rosetta.string("projectSubmittable.editor_validity_duration")}</label>
                                        <div className={"input-group"}>
                                            <input type={"text"} className={"form-control"} value={validityDurationNumber} onChange={(e) => setValidityDurationNumber(e.target.value)} />
                                            <select className={"form-select"} value={validityDurationUnit} onChange={(e) => setValidityDurationUnit(e.target.value)}>
                                                <option value={TimeUtil.YEAR}>{Rosetta.string("time.year")}</option>
                                                <option value={TimeUtil.MONTH}>{Rosetta.string("time.month")}</option>
                                                <option value={TimeUtil.WEEK}>{Rosetta.string("time.week")}</option>
                                                <option value={TimeUtil.DAY}>{Rosetta.string("time.day")}</option>
                                                <option value={TimeUtil.HOUR}>{Rosetta.string("time.hour")}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className={"col-12 mt-4 text-center"}>
                        <button className={"btn btn-primary"} onClick={() => submitProjectSubmittableOverNetwork()}>{Rosetta.string("common.save")}</button>
                    </div>
                </div>

            </div>

            <ProjectSelectionModal
                shown={projectSelectionShown}
                callback={clientSelectionDidCallback} />

            <FormSelectionModal
                shown={formSelectionShown}
                callback={formSelectionDidCallback} />
        </div>
    );

}