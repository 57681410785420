import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../../rosetta/Rosetta";
import {NavLink} from "react-router-dom";

export const UserGuideLandingScreen = (props) => {

    return (
        <div className={"app-screen user-guide-landing-screen"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("user_guide.title")} />
                </div>
            </div>

            <div className={"row mt-4 justify-content-center"}>
                <div className={"col-12 col-md-6 col-lg-4"}>
                    <h3>{Rosetta.string("user_guide.form_title")}</h3>

                    <ul className={"list-group"}>
                        <NavLink to={"/user-guide/forms/1/overview"} className={"list-group-item list-group-item-action"}>{Rosetta.string("user_guide.form_overview")}</NavLink>
                        <NavLink to={"/user-guide/forms/2/publishing"} className={"list-group-item list-group-item-action"}>{Rosetta.string("user_guide.form_version")}</NavLink>
                        <NavLink to={"/user-guide/forms/3/sections"} className={"list-group-item list-group-item-action"}>{Rosetta.string("user_guide.form_sections")}</NavLink>
                        <NavLink to={"/user-guide/forms/4/questions"} className={"list-group-item list-group-item-action"}>{Rosetta.string("user_guide.form_questions")}</NavLink>
                        <NavLink to={"/user-guide/forms/5/submittables"} className={"list-group-item list-group-item-action"}>{Rosetta.string("user_guide.form_submittables")}</NavLink>
                    </ul>
                </div>
            </div>
        </div>
    )

}