import {ScreenTitle} from "../../../screenTitle/ScreenTitle";
import Rosetta from "../../../../../rosetta/Rosetta";
import {FilterText} from "../../../../filter/FilterText";
import TableComponent, {Column, TablePaginator} from "../../../../table/TableComponent";
import {useEffect, useState} from "react";
import {OffcanvasActions} from "../../../../offcanvas/Offcanvas";
import {useParams} from "react-router-dom";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../../network/API";
import {Toast} from "../../../../toast/TokyoToaster";
import {DocumentCollectionEditorModal} from "./DocumentCollectionEditorModal";
import {ProjectSelectionModal} from "../../../common/ProjectSelectionModal";

export const DocumentCollectionListScreen = (props) => {

    const {typeID} = useParams();

    const [documentType, setDocumentType] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const [networkInFlight, setNetworkInFlight] = useState(false);
    const [typeNetworkInFlight, setTypeNetworkInFlight] = useState(false);

    const [keyword, setKeyword] = useState("");
    const [page, setPage] = useState(1);

    const [editorShown, setEditorShown] = useState(false);
    const [editorID, setEditorID] = useState(null);

    const [filterProject, setFilterProject] = useState(null);
    const [projectSelectionShown, setProjectSelectionShown] = useState(false);

    useEffect(() => {
        if (typeID) {
            fetchTypeFromNetwork();
        }
        fetchDocumentsFromNetwork();
    }, []);

    useEffect(() => {
        fetchDocumentsFromNetwork();
    }, [page, keyword, filterProject])

    function showEditor(id) {
        setEditorID(id);
        setEditorShown(true);
    }

    function editorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                fetchDocumentsFromNetwork();
            }
            setEditorShown(false);
        }
    }

    function projectSelectionDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                setFilterProject(data);
            }
            setProjectSelectionShown(false);
        }
    }

    function fetchTypeFromNetwork() {
        if (typeNetworkInFlight) return;
        setTypeNetworkInFlight(true);

        let data = {
            ids : [typeID],
            page : 1,
            pageSize : 1
        };

        if (filterProject) {
            data.projectID = filterProject.id;
        }

        Axios.post(ENDPOINTS.project.getProjectCollectionDocumentTypes, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setDocumentType(resp.data.data[0]);
                    }
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setTypeNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setTypeNetworkInFlight(false);
                // TODO Error
            });
    }

    function fetchDocumentsFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let data = {
            page,
            documentTypeIDs : [typeID],
            keyword
        };

        if (filterProject !== null) {
            data.projectIDs = [filterProject.id];
        }

        Axios.post(ENDPOINTS.project.getProjectCollectionDocuments, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setDocuments(resp.data.data);
                    setTotalCount(resp.data.count);
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                // TODO Error
            });
    }

    let projectFilterClear = [];
    if (filterProject) {
        projectFilterClear = (
            <span className={"badge bg-secondary clickable"} onClick={() => setFilterProject(null)}>{Rosetta.string("common.clear")}</span>
        )
    }

    return (
        <div className={"app-screen document-collection-type-list-screen"}>

            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={(documentType ? documentType.documentType : "-")} />
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-4"}>
                        <label>{Rosetta.string("document_collections.document_project")} {projectFilterClear}</label>
                        <span className={"form-control clickable"} onClick={() => setProjectSelectionShown(true)}>{ filterProject ? filterProject.project_name : Rosetta.string("document_collections.document_project_all") }</span>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <label>&nbsp;</label>
                        <FilterText callback={setKeyword} />
                    </div>

                    <div className={"col-11 col-md-4 text-end"}>
                        <div><label>&nbsp;</label></div>
                        <button className={"btn btn-primary"} onClick={() => showEditor()}>{Rosetta.string("document_collections.document_new")}</button>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                data={documents}
                                placeholderMode={networkInFlight}
                                placeholderRows={20}
                                onRowClick={(row) => showEditor(row.id)}>

                                <Column name={"projectName"} title={Rosetta.string("document_collections.document_table_project")} />
                                <Column name={"notes"} title={Rosetta.string("document_collections.document_table_notes")} />
                                <Column name={"id"} className={"text-end"} render={(data, row) => (
                                    <>
                                        <button className={"btn btn-primary"}>{Rosetta.string("common.edit")}</button>
                                    </>
                                )} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container mt-1"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={page}
                                    totalCount={totalCount}
                                    pageSize={20}
                                    onClick={setPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DocumentCollectionEditorModal
                shown={editorShown}
                documentID={editorID}
                typeID={typeID}
                callback={editorDidCallback} />

            <ProjectSelectionModal
                shown={projectSelectionShown}
                callback={projectSelectionDidCallback} />

        </div>
    );

}