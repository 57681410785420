import BaseComponent from "../../../../BaseComponent";
import Rosetta from "../../../../../rosetta/Rosetta";
import AlertModal from "../../../../alertmodal/AlertModal";
import WindowUtil from "../../../../../util/WindowUtil";

export default class FormSectionEditorComponent extends BaseComponent {

    dismissTimeout = undefined;

    constructor(props, context) {
        super(props, context);

        this.initState({
            isDismissing : false,
            sectionTitle : "",
            sectionCollapsable : "0",
            sectionSpecialOption : "0",
            sectionFormInForm : "0"
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.shown !== this.props.shown) {
            if (this.props.shown) {
                WindowUtil.lockBodyScroll();

                if (this.props.section !== undefined) {
                    this.populateObjectIntoState(this.props.section, "section", () => {
                        console.log(this.state);
                    });
                } else {
                    this.setState({
                        sectionLocalId : undefined,
                        sectionTitle : "",
                        sectionCollapsable : "0",
                        sectionFormInForm : "0",
                        sectionQuestions : []
                    });
                }

                this.setState({
                    isDismissing: false
                });
            } else {
                WindowUtil.unlockBodyScroll();
            }
        }
    }

    dismissWasRequested = (success) => {
        if (success === undefined) {
            success = false;
        }

        if (this.state.isDismissing) return;

        this.setState({
            isDismissing : true
        });

        clearTimeout(this.dismissTimeout);
        this.dismissTimeout = setTimeout(() => {
            if (this.props.callback !== undefined) {
                let out = undefined;
                if (success) {
                    out = this.buildSection();
                }
                this.props.callback(out);
            }
        }, 200);
    }

    submitForm = () => {
        if (this.state.sectionTitle === "") {
            AlertModal.showError(Rosetta.string("validation.missing_property", { "label" : Rosetta.string("form_section.editor_section_title") }));
            return false;
        }

        return this.dismissWasRequested(true);
    }

    buildSection = () => {
        return {
            localId : this.state.sectionLocalId,
            title : this.state.sectionTitle,
            collapsable : this.state.sectionCollapsable,
            dataSource : this.state.sectionDataSource,
            specialOption : this.state.sectionSpecialOption,
            dynamicFormTemplateId : this.state.sectionDynamicFormTemplateId,
            questions : this.state.sectionQuestions,
            formInForm : this.state.sectionFormInForm
        };
    }

    dataSourceDidChange = (uri) => {
        this.setState({
            sectionDataSource : uri
        });
    }

    render() {
        if (!this.props.shown) return [];

        // We mutate key here to "recreate" the element in the DOM to allow us to replay the entry animation on exit
        let elemKey = "sectionslide_one";
        let dismissClass = "";
        if (this.state.isDismissing) {
            dismissClass = " dismissing";
            elemKey = "sectionslide_two";
        }

        return (
            <div className={"form-section-editor-component" + dismissClass} key={elemKey}>
                <div className={"offcanvas-scrim"} />

                <div className="offcanvas offcanvas-end">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title">{Rosetta.string("form_section.editor_title")}</h5>
                        <button type="button"
                                className="btn-close text-reset"
                                aria-label={Rosetta.string("common.close")}
                                onClick={() => this.dismissWasRequested(false)} />
                    </div>
                    <div className="offcanvas-body">

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <label>{Rosetta.string("form_section.editor_section_title")}</label>
                                <input type={"text"} className={"form-control"} name={"sectionTitle"} value={this.state.sectionTitle} onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col-12"}>
                                <label>{Rosetta.string("form_section.editor_form_in_form")}</label>
                                <div className="alert alert-light">{Rosetta.string("form_section.editor_form_in_form_explain")}</div>
                                <select className={"form-select"} name={"sectionFormInForm"} value={this.state.sectionFormInForm} onChange={this.handleChange}>
                                    <option value={"0"}>{Rosetta.string("common.no")}</option>
                                    <option value={"1"}>{Rosetta.string("common.yes")}</option>
                                </select>
                            </div>
                        </div>

                    </div>

                    <div className={"offcanvas-footer"}>
                        <div className={"row"}>
                            <div className={"col-12 text-end"}>
                                <button className={"btn btn-success"} onClick={() => this.submitForm()}>{Rosetta.string("common.save")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}