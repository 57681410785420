import BaseComponent from "../../../../../BaseComponent";

import "./FormInputDropDownComponent.css";

export default class FormInputDropDownComponent extends BaseComponent {

    render() {
        let label = "";
        let options = [];

        if (this.props.input !== undefined) {
            label = this.props.input.label;
            options = this.props.input.options;
        }

        if (options === undefined || options === null) {
            options = [];
        }

        return (
            <div className={"form-input-drop-down-component"}>
                <div className={"label"}>{label}</div>
                <select className={"form-select"}>
                    {
                        options.map((option) => (
                            <option value={option.value}>{option.label}</option>
                        ))
                    }
                </select>
            </div>
        )
    }

}