import {ScreenTitle} from "../../../screenTitle/ScreenTitle";
import Rosetta from "../../../../../rosetta/Rosetta";
import {NavLink, useParams} from "react-router-dom";
import {ImageUtil} from "../../../../../util/ImageUtil";

import iconEdit from "../../../../../assets/images/edit.svg";
import iconUp from "../../../../../assets/images/arrow_up.svg";
import iconDown from "../../../../../assets/images/arrow_down.svg";
import iconDelete from "../../../../../assets/images/delete.svg";

import "./FormUserGuideContentScreen.css";

export const FormUserGuideSections = {
    OVERVIEW : 1,
    VERSIONS : 2,
    SECTIONS : 3,
    QUESTIONS : 4,
    SUBMITTABLES : 5
};

export const FormUserGuideContentScreen = (props) => {

    const {contentID} = useParams();

    const guideContent = [
        {
            id : FormUserGuideSections.OVERVIEW,
            title : Rosetta.string("user_guide.form_overview"),
            content : [
                <p>{Rosetta.string("user_guide.form_overview_1")}</p>,
                <p>{Rosetta.string("user_guide.form_overview_2")}</p>,
                <p>{Rosetta.string("user_guide.form_overview_2")}</p>
            ]
        },
        {
            id : FormUserGuideSections.VERSIONS,
            title : Rosetta.string("user_guide.form_version"),
            content : [
                <p>{Rosetta.string("user_guide.form_version_1")}</p>,
                <p>{Rosetta.string("user_guide.form_version_2")}</p>
            ]
        },
        {
            id : FormUserGuideSections.SECTIONS,
            title : Rosetta.string("user_guide.form_sections"),
            content : [
                <p>{Rosetta.string("user_guide.form_sections_1")}</p>,
                <p>{Rosetta.string("user_guide.form_sections_2")}</p>,
                <div className={"mt-2"}>
                    <table className={"table"}>
                        <thead>
                        <th>{Rosetta.string("user_guide.form_sections_opt_option")}</th>
                        <th>{Rosetta.string("user_guide.form_sections_opt_desc")}</th>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{Rosetta.string("user_guide.form_sections_opt_title")}</td>
                            <td>{Rosetta.string("user_guide.form_sections_opt_title_desc")}</td>
                        </tr>
                        <tr>
                            <td>{Rosetta.string("user_guide.form_sections_opt_fif")}</td>
                            <td>{Rosetta.string("user_guide.form_sections_opt_fif_desc")}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>,
                <h4>{Rosetta.string("user_guide.form_sections_options")}</h4>,
                <div className={"mt-2"}>
                    <table className={"table"}>
                        <thead>
                        <th>{Rosetta.string("user_guide.form_sections_option_icon")}</th>
                        <th>{Rosetta.string("user_guide.form_sections_opt_desc")}</th>
                        </thead>
                        <tbody>
                        <tr>
                            <td className={"text-center"}><span className={"action-icon"} style={{backgroundImage : ImageUtil.background(iconEdit)}} /></td>
                            <td>{Rosetta.string("user_guide.form_sections_options_edit")}</td>
                        </tr>
                        <tr>
                            <td className={"text-center"}><span className={"action-icon"} style={{backgroundImage : ImageUtil.background(iconUp)}} /></td>
                            <td>{Rosetta.string("user_guide.form_sections_options_up")}</td>
                        </tr>
                        <tr>
                            <td className={"text-center"}><span className={"action-icon"} style={{backgroundImage : ImageUtil.background(iconDown)}} /></td>
                            <td>{Rosetta.string("user_guide.form_sections_options_down")}</td>
                        </tr>
                        <tr>
                            <td className={"text-center"}><span className={"action-icon"} style={{backgroundImage : ImageUtil.background(iconDelete)}} /></td>
                            <td>{Rosetta.string("user_guide.form_sections_options_delete")}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            ]
        },
        {
            id : FormUserGuideSections.QUESTIONS,
            title : Rosetta.string("user_guide.form_questions"),
            content : [
                <p>{Rosetta.string("user_guide.form_questions_1")}</p>,
                <p>{Rosetta.string("user_guide.form_questions_2")}</p>,
                <h4>{Rosetta.string("user_guide.form_question_options")}</h4>,
                <div className={"mt-2"}>
                    <table className={"table"}>
                        <thead>
                        <th>{Rosetta.string("user_guide.form_sections_option_icon")}</th>
                        <th>{Rosetta.string("user_guide.form_sections_opt_desc")}</th>
                        </thead>
                        <tbody>
                        <tr>
                            <td className={"text-center"}><span className={"action-icon"} style={{backgroundImage : ImageUtil.background(iconEdit)}} /></td>
                            <td>{Rosetta.string("user_guide.form_questions_options_edit")}</td>
                        </tr>
                        <tr>
                            <td className={"text-center"}><span className={"action-icon"} style={{backgroundImage : ImageUtil.background(iconUp)}} /></td>
                            <td>{Rosetta.string("user_guide.form_questions_options_up")}</td>
                        </tr>
                        <tr>
                            <td className={"text-center"}><span className={"action-icon"} style={{backgroundImage : ImageUtil.background(iconDown)}} /></td>
                            <td>{Rosetta.string("user_guide.form_questions_options_down")}</td>
                        </tr>
                        <tr>
                            <td className={"text-center"}><span className={"action-icon"} style={{backgroundImage : ImageUtil.background(iconDelete)}} /></td>
                            <td>{Rosetta.string("user_guide.form_questions_options_delete")}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>,
                <h4 className={"mt-4"}>{Rosetta.string("user_guide.form_questions_keys")}</h4>,
                <p>{Rosetta.string("user_guide.form_questions_keys_1")}</p>,
                <p>{Rosetta.string("user_guide.form_questions_keys_2")}</p>,
                <p>{Rosetta.string("user_guide.form_questions_keys_3")}</p>,
                <h4 className={"mt-4"}>{Rosetta.string("user_guide.form_questions_types")}</h4>,
                <p>{Rosetta.string("user_guide.form_questions_types_1")}</p>,
                <p>{Rosetta.string("user_guide.form_questions_types_2")}</p>,
                <div className={"mt-2"}>
                    <table className={"table"}>
                        <thead>
                        <th>{Rosetta.string("user_guide.form_questions_types_name")}</th>
                        <th>{Rosetta.string("user_guide.form_questions_types_desc")}</th>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{Rosetta.string("user_guide.form_questions_type_label")}</td>
                            <td>{Rosetta.string("user_guide.form_questions_type_label_desc")}</td>
                        </tr>
                        <tr>
                            <td>{Rosetta.string("user_guide.form_questions_type_text")}</td>
                            <td>{Rosetta.string("user_guide.form_questions_type_text_desc")}</td>
                        </tr>
                        <tr>
                            <td>{Rosetta.string("user_guide.form_questions_type_number")}</td>
                            <td>{Rosetta.string("user_guide.form_questions_type_number_desc")}</td>
                        </tr>
                        <tr>
                            <td>{Rosetta.string("user_guide.form_questions_type_date")}</td>
                            <td>{Rosetta.string("user_guide.form_questions_type_date_desc")}</td>
                        </tr>
                        <tr>
                            <td>{Rosetta.string("user_guide.form_questions_type_radio")}</td>
                            <td>{Rosetta.string("user_guide.form_questions_type_radio_desc")}</td>
                        </tr>
                        <tr>
                            <td>{Rosetta.string("user_guide.form_questions_type_signature")}</td>
                            <td>{Rosetta.string("user_guide.form_questions_type_signature_desc")}</td>
                        </tr>
                        <tr>
                            <td>{Rosetta.string("user_guide.form_questions_type_photo")}</td>
                            <td>{Rosetta.string("user_guide.form_questions_type_photo_desc")}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>,
            ]
        },
        {
            id : FormUserGuideSections.SUBMITTABLES,
            title : Rosetta.string("user_guide.form_submittables"),
            content : [
                <p>{Rosetta.string("user_guide.form_submittables_1")}</p>,
                <p>{Rosetta.string("user_guide.form_submittables_2")}</p>,
                <p>{Rosetta.string("user_guide.form_submittables_3")}</p>,
                <p>{Rosetta.string("user_guide.form_submittables_4")}</p>,
                <p>{Rosetta.string("user_guide.form_submittables_5")}</p>,
                <h4 className={"mt-4"}>{Rosetta.string("user_guide.form_submittables_types")}</h4>,
                <div className={"mt-2"}>
                    <table className={"table"}>
                        <thead>
                        <th>{Rosetta.string("user_guide.form_submittables_types_type")}</th>
                        <th>{Rosetta.string("user_guide.form_submittables_types_desc")}</th>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{Rosetta.string("user_guide.form_submittables_form")}</td>
                            <td>{Rosetta.string("user_guide.form_submittables_form_desc")}</td>
                        </tr>
                        <tr>
                            <td>{Rosetta.string("user_guide.form_submittables_inspection")}</td>
                            <td>{Rosetta.string("user_guide.form_submittables_inspection_desc")}</td>
                        </tr>
                        <tr>
                            <td>{Rosetta.string("user_guide.form_submittables_permit")}</td>
                            <td>{Rosetta.string("user_guide.form_submittables_permit_desc")}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>,
            ]
        }
    ];

    let title = "";
    let content = [];

    let bottomNav = [];

    for (let i = 0; i < guideContent.length; i++) {
        let sectionData = guideContent[i];
        if (sectionData.id === parseInt(contentID)) {
            title = sectionData.title;
            content = sectionData.content;

            if (i > 0) {
                let prevContent = guideContent[i - 1];
                let linkName = prevContent.title.split(" ")[0].toLowerCase();
                bottomNav.push(
                    <NavLink to={"/user-guide/forms/" + prevContent.id + "/" + linkName} className={"list-group-item list-group-item-action flex-fill navigation-widget"}>
                        <strong>{Rosetta.string("common.previous")}</strong>
                        {prevContent.title}
                    </NavLink>
                )
            } else {
                bottomNav.push(
                    <div className={"list-group-item flex-fill"} />
                )
            }

            if (i < (guideContent.length - 1)) {
                let nextContent = guideContent[i + 1];
                let linkName = nextContent.title.split(" ")[0].toLowerCase();
                bottomNav.push(
                    <NavLink to={"/user-guide/forms/" + nextContent.id + "/" + linkName} className={"list-group-item list-group-item-action flex-fill navigation-widget text-end"}>
                        <strong>{Rosetta.string("common.next")}</strong>
                        {nextContent.title}
                    </NavLink>
                );
            } else {
                bottomNav.push(
                    <div className={"list-group-item"} />
                )
            }
        }
    }


    if (bottomNav.length > 0) {
        bottomNav = (
            <div className={"list-group list-group-horizontal"}>
                {bottomNav}
            </div>
        )
    }

    return (
        <div className={"app-screen form-user-guide"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <ScreenTitle title={Rosetta.string("user_guide.form_title")} />
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-md-10 col-md-8"}>

                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <h3>{title}</h3>
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        {content}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={"row mt-4 mb-4 justify-content-center"}>
                     <div className={"col-12 col-md-6"}>
                         {bottomNav}
                     </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        <NavLink to={"/user-guide"} className={"btn btn-primary"}>{Rosetta.string("user_guide.return_to_user_guide")}</NavLink>
                    </div>
                </div>

            </div>
        </div>
    )

}
