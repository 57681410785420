import BaseComponent from "../../../../../BaseComponent";

import "./FormInputSignatureComponent.css";

export default class FormInputSignatureComponent extends BaseComponent {

    render() {
        let label = "";
        let prefix = [];
        let suffix = [];

        if (this.props.input !== undefined) {
            label = this.props.input.label;

            if (this.props.input.prefixLabel !== undefined && this.props.input.prefixLabel !== "") {
                prefix = (<div className={"in-label prefix"}>{this.props.input.prefixLabel}</div>);
            }

            if (this.props.input.suffixLabel !== undefined && this.props.input.suffixLabel !== "") {
                suffix = (<div className={"in-label suffix"}>{this.props.input.suffixLabel}</div>);
            }
        }

        return (
            <div className={"form-input-signature-component"}>
                <div className={"label"}>{label}</div>

                <div className={"signature-container"}>
                    {prefix}
                    <div className={"signature-area"} />
                    {suffix}
                </div>

            </div>
        )
    }

}