import BaseComponent from "../../../../../BaseComponent";

import "./FormInputRatingBarComponent.css";

export default class FormInputRatingBarComponent extends BaseComponent {

    render() {
        let label = "";
        let prefix = [];
        let suffix = [];

        if (this.props.input !== undefined) {
            label = this.props.input.label;

            if (this.props.input.prefixLabel !== undefined && this.props.input.prefixLabel !== "") {
                prefix = (<div className={"in-label prefix"}>{this.props.input.prefixLabel}</div>);
            }

            if (this.props.input.suffixLabel !== undefined && this.props.input.suffixLabel !== "") {
                suffix = (<div className={"in-label suffix"}>{this.props.input.suffixLabel}</div>);
            }
        }

        return (
            <div className={"form-input-rating-bar-component"}>
                <div className={"label"}>{label}</div>

                <div className={"rating-bar-container"}>
                    {prefix}
                    <div className={"rating-bar"}>
                        <div className={"rating-bar-line"} />
                        <div className={"rating-bar-handle"} />
                    </div>
                    {suffix}
                </div>

            </div>
        )
    }

}