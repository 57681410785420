import {ScreenTitle} from "../../../screenTitle/ScreenTitle";
import Rosetta from "../../../../../rosetta/Rosetta";
import {FilterText} from "../../../../filter/FilterText";
import TableComponent, {Column, TablePaginator} from "../../../../table/TableComponent";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../../network/API";
import AlertModal from "../../../../alertmodal/AlertModal";
import {DocumentCollectionTypeEditorModal} from "./DocumentCollectionTypeEditorModal";
import {OffcanvasActions} from "../../../../offcanvas/Offcanvas";
import {Navigator} from "../../../../../util/Navigator";

export const DocumentCollectionTypeListScreen = (props) => {

    const [documentTypes, setDocumentTypes] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [keyword, setKeyword] = useState("");
    const [page, setPage] = useState(1);

    const [editorShown, setEditorShown] = useState(false);
    const [editorTypeID, setEditorTypeID] = useState(null);

    useEffect(() => {
        fetchDocumentTypesFromNetwork();
    }, []);

    useEffect(() => {
        fetchDocumentTypesFromNetwork();
    }, [keyword, page]);

    function showTypeEditor(id) {
        setEditorTypeID(id);
        setEditorShown(true);
    }

    function editorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                fetchDocumentTypesFromNetwork();
            }
            setEditorShown(false);
        }
    }

    function moveToTypeView(id) {
        if (id) {
            Navigator.navigate("/content-config/collection-documents/" + id);
        }
    }

    function fetchDocumentTypesFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let data = {
            page,
            keyword
        };

        Axios.post(ENDPOINTS.project.getProjectCollectionDocumentTypes, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setDocumentTypes(resp.data.data);
                    setTotalCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PCDT1000C" }));
            });
    }

    return (
        <div className={"app-screen document-collection-type-list-screen"}>

            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("document_collections.title")} />
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-4"}>
                        <FilterText callback={setKeyword} />
                    </div>

                    <div className={"col-1 col-md-4"} />

                    <div className={"col-11 col-md-4 text-end"}>
                        <button className={"btn btn-primary"} onClick={() => showTypeEditor()}>{Rosetta.string("document_collections.new_type")}</button>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                data={documentTypes}
                                placeholderMode={networkInFlight}
                                placeholderRows={20}>

                                <Column name={"documentType"} title={Rosetta.string("document_collections.table_title")} />
                                <Column name={"documentCount"} className={"text-center"} title={Rosetta.string("document_collections.table_count")} />
                                <Column name={"id"} className={"text-end"} render={(data, row) => (
                                    <>
                                        <button className={"btn btn-primary"} onClick={() => moveToTypeView(data)}>{Rosetta.string("common.view")}</button>
                                        &nbsp;
                                        <button className={"btn btn-primary"} onClick={() => showTypeEditor(data)}>{Rosetta.string("common.edit")}</button>
                                    </>
                                )} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container mt-1"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={page}
                                    totalCount={totalCount}
                                    pageSize={20}
                                    onClick={setPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DocumentCollectionTypeEditorModal
                shown={editorShown}
                typeID={editorTypeID}
                callback={editorDidCallback} />

        </div>
    );

}