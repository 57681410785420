import BaseComponent from "../../../../../BaseComponent";
import FormInputLabelComponent from "./FormInputLabelComponent";
import FormInputMultiSelectComponent from "./FormInputMultiSelectComponent";
import FormInputTextComponent from "./FormInputTextComponent";
import FormInputDateTimeComponent from "./FormInputDateTimeComponent";
import FormInputRatingBarComponent from "./FormInputRatingBarComponent";
import FormInputRadioComponent from "./FormInputRadioComponent";
import FormInputDropDownComponent from "./FormInputDropDownComponent";
import FormInputStaffSelectorComponent from "./FormInputStaffSelectorComponent";
import FormInputEquipmentSelectorComponent from "./FormInputEquipmentSelectorComponent";
import FormInputSignatureComponent from "./FormInputSignatureComponent";

import "./FormInputComponent.css";

import arrowUp from "../../../../../../assets/images/arrow_up.svg";
import arrowDown from "../../../../../../assets/images/arrow_down.svg";
import trash from "../../../../../../assets/images/delete.svg";
import {Form} from "../../../../../../util/Form";
import {FormInputPhotoSelectorComponent} from "./FormInputPhotoSelectorComponent";
import {FormInputFormInFormComponent} from "./FormInputFormInFormComponent";

export default class FormInputComponent extends BaseComponent {

    dispatchOnClickCallback = () => {
        if (this.props.onClick !== undefined) {
            this.props.onClick(this.props.input);
        }
    }

    dispatchCallback = (actionName) => {
        if (this.props.callback !== undefined) {
            this.props.callback({name : actionName});
        }
    }

    render() {
        let out = [];

        if (this.props.input !== undefined) {
            let inputType = parseInt(this.props.input.dataTypeID);

            switch (inputType) {
                case Form.DATA_TYPE.LABEL:
                    out = (
                        <FormInputLabelComponent {...this.props} />
                    );
                    break;
                case Form.DATA_TYPE.BOOLEAN:
                case Form.DATA_TYPE.TRINARY:
                    out = (
                        <FormInputMultiSelectComponent {...this.props} />
                    )
                    break;
                case Form.DATA_TYPE.TEXT:
                case Form.DATA_TYPE.NUMBER:
                    out = (
                        <FormInputTextComponent {...this.props} />
                    )
                    break;
                case Form.DATA_TYPE.DATE_TIME:
                    out = (
                        <FormInputDateTimeComponent {...this.props} />
                    )
                    break;
                case Form.DATA_TYPE.RADIO:
                    out = (
                        <FormInputRadioComponent {...this.props} />
                    )
                    break;
                case Form.DATA_TYPE.DROPDOWN:
                    out = (
                        <FormInputDropDownComponent {...this.props} />
                    )
                    break;
                case Form.DATA_TYPE.RATING_BAR:
                    out = (
                        <FormInputRatingBarComponent {...this.props} />
                    )
                    break;
                case Form.DATA_TYPE.PHOTO_SELECTOR:
                    out = (
                        <FormInputPhotoSelectorComponent {...this.props} />
                    )
                    break;
                case Form.DATA_TYPE.FORM_IN_FORM:
                    out = (
                        <FormInputFormInFormComponent {...this.props} />
                    )
                    break;
                case Form.DATA_TYPE.SIGNATURE:
                    out = (
                        <FormInputSignatureComponent {...this.props} />
                    )
                    break;
                default :
                    out = (
                        <div>Unsupported type: {inputType}</div>
                    )
            }
        }

        return (
            <div className={"form-input-component"}>
                <div className={"input-container"}>
                    {out}
                </div>
            </div>
        );
    }
}