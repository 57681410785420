import {useEffect, useState} from "react";
import {Offcanvas, OffcanvasActions, OffcanvasGravity} from "../../../../offcanvas/Offcanvas";
import Rosetta from "../../../../../rosetta/Rosetta";
import {ClientSelectionModal} from "../../../common/ClientSelectionModal";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../../network/API";
import {Toast} from "../../../../toast/TokyoToaster";
import WindowUtil from "../../../../../util/WindowUtil";
import {LoadingSpinner} from "../../../../loading/LoadingSpinner";
import Validator from "../../../../../util/Validator";
import AlertModal from "../../../../alertmodal/AlertModal";

export const DocumentSectionEditorModal = (props) => {

    const {shown} = props;
    const {sectionID} = props;
    const {parentSectionID} = props;
    const {callback} = props;
    const {clientID} = props;

    const [section, setSection] = useState(null);
    const [sectionTitle, setSectionTitle] = useState("");
    const [sectionClient, setSectionClient] = useState(null);
    const [sectionActive, setSectionActive] = useState(1);

    const [forceDismiss, setForceDismiss] = useState(false);

    const [clientSelectorShown, setClientSelectorShown] = useState(false);

    const [sectionNetworkInFlight, setSectionNetworkInFlight] = useState(false);

    const [callbackData, setCallbackData] = useState(null);

    useEffect(() => {
        if (shown) {
            if (sectionID) {
                fetchSectionFromNetwork();
            }
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();
            setSection(null);
            setSectionTitle("");
            setSectionClient(null);
            setSectionActive(1);
            setForceDismiss(false);
            setCallbackData(null);
        }
    }, [shown]);

    useEffect(() => {
        if (section) {
            setSectionTitle(section.title);
            setSectionClient({
                id : section.clientID,
                company_name : section.clientName,
                active : section.active
            });
        }
    }, [section]);

    useEffect(() => {
        // Quick bodge: When the Client Selector closes it releases Window Lock
        // re-apply the Window Lock now to keep UI consistency.
        if (!clientSelectorShown && shown) {
            WindowUtil.lockBodyScroll();
        }
    }, [clientSelectorShown])

    function handleCallback(action) {
        if (callback) {
            callback(action, callbackData);
        }
    }

    function clientSelectorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                setSectionClient(data);
            }
            setClientSelectorShown(false);
        }
    }

    function fetchSectionFromNetwork() {
        if (!sectionID || sectionNetworkInFlight) return;
        setSectionNetworkInFlight(true);

        let data = {
            ids : [sectionID],
            parentSectionID,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.project.getProjectDocumentSections, data)
            .then((r) => {
                let error = null;

                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setSection(resp.data.data[0]);
                    } else {
                        error = Rosetta.string("document_requests.section_editor_not_found");
                    }
                } else {
                    error = API.formatError(resp);
                }

                if (error !== null) {
                    Toast.show(
                        Rosetta.string("common.error"),
                        error,
                        Toast.ERROR,
                        Toast.LONG
                    )
                    setForceDismiss(true);
                }

                setSectionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSectionNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    Rosetta.string("common.error_common_unknown", { error_code : "SEA1000C" }),
                    Toast.ERROR,
                    Toast.LONG
                )
            })
    }

    function submitSectionOverNetwork() {
        if (sectionNetworkInFlight) return;

        const validationResult = Validator.validateCreateFormData({
            sectionID,
            parentSectionID,
            sectionTitle,
            clientID : sectionClient ? sectionClient.id : clientID,
            sectionActive
        }, [
            Validator.rule("sectionID", "int", "", "id", true),
            Validator.rule("parentSectionID", "int", "", "parentSectionID", true),
            Validator.rule("sectionTitle", "string", Rosetta.string("document_requests.section_editor_section_title"), "title"),
            Validator.rule("clientID", "string", Rosetta.string("document_requests.section_editor_section_client"), "clientID"),
            Validator.rule("sectionActive", "int", "", "active", true)
        ]);

        if (!validationResult.success) {
            AlertModal.showError(validationResult.error);
            return;
        }

        setSectionNetworkInFlight(true);

        Axios.post(ENDPOINTS.project.submitProjectDocumentSectionAdmin, validationResult.formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    let toastMessage = "document_requests.section_editor_success_created";
                    if (sectionID) {
                        toastMessage = "document_requests.section_editor_success_edited";
                    }
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string(toastMessage),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setCallbackData(resp.data.section);
                    setForceDismiss(true);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSectionNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSectionNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "DSSE2000C" }));
            });
    }

    if (!shown) return [];

    let actions = (
        <div className={"col-12 text-center"}>
            <button className={"btn btn-primary"} onClick={() => submitSectionOverNetwork()}>{Rosetta.string("common.save")}</button>
        </div>
    )
    if (sectionNetworkInFlight) {
        actions = (
            <div className={"col-12 text-center"}>
                <LoadingSpinner small={true} inline={true} />
            </div>
        )
    }

    let clientSelector = [];
    if (!clientID) {
        clientSelector = (
            <div className={"row"}>
                <div className={"col-12"}>
                    <label>{Rosetta.string("document_requests.section_editor_section_client")}</label>
                    <span className={"form-control"} onClick={() => setClientSelectorShown(true)}>{(sectionClient) ? sectionClient.company_name : Rosetta.string("common.please_select")}</span>
                </div>
            </div>
        );
    }

    return (
        <>
            <Offcanvas
                shown={true}
                title={Rosetta.string("document_requests.section_editor_title")}
                gravity={OffcanvasGravity.END}
                callback={handleCallback}
                forceDismiss={forceDismiss}>

                {clientSelector}

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("document_requests.section_editor_section_title")}</label>
                        <input type={"text"} className={"form-control"} value={sectionTitle} onChange={(e) => setSectionTitle(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("document_requests.section_editor_section_active")}</label>
                        <select className={"form-select"} value={sectionActive} onChange={(e) => setSectionActive(e.target.value)}>
                            <option value={"0"}>{Rosetta.string("common.no")}</option>
                            <option value={"1"}>{Rosetta.string("common.yes")}</option>
                        </select>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    {actions}
                </div>
            </Offcanvas>

            <ClientSelectionModal
                shown={clientSelectorShown}
                callback={clientSelectorDidCallback} />
        </>
    )

}