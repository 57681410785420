import {useEffect, useState} from "react";
import WindowUtil from "../../../../util/WindowUtil";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import Rosetta from "../../../../rosetta/Rosetta";
import {PlaceholderText} from "../../../placeholder/PlaceholderText";
import {Offcanvas, OffcanvasGravity} from "../../../offcanvas/Offcanvas";
import {FilterText} from "../../../filter/FilterText";

export const FormSelectionModal = (props) => {

    const {shown} = props;
    const {gravity} = props;

    const [forms, setForms] = useState([]);
    const [formCount, setFormCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [keyword, setKeyword] = useState("");

    const [formNetworkInFlight, setFormNetworkInFlight] = useState(false);

    const [callbackData, setCallbackData] = useState(null);
    const [forceDismiss, setForceDismiss] = useState(false);

    useEffect(() => {
        if (shown) {
            WindowUtil.lockBodyScroll();
            getFormsFromNetwork();
        } else {
            WindowUtil.unlockBodyScroll();
            setForms([]);
            setFormCount(0);
            setCurrentPage(1);
            setKeyword("");
            setForceDismiss(false);
            setCallbackData(null);
        }
    }, [shown]);

    useEffect(() => {
        getFormsFromNetwork();
    }, [currentPage]);

    useEffect(() => {
        setForms([]);
        setFormCount(0);
        if (currentPage > 1) {
            setCurrentPage(1);
        } else {
            getFormsFromNetwork();
        }
    }, [keyword]);

    function handleCallback(action) {
        if (props.callback !== undefined) {
            props.callback(action, callbackData);
        }
    }

    function selectUser(user) {
        setCallbackData(user);
        setForceDismiss(true);
    }

    function getFormsFromNetwork() {
        if (!shown || formNetworkInFlight) return;
        setFormNetworkInFlight(true);

        let formData = {
            keyword,
            page : currentPage
        };

        Axios.post(ENDPOINTS.form.getFormTemplates, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    let newForms = [...forms];
                    for (let i = 0; i < resp.data.forms.length; i++) {
                        newForms.push(resp.data.forms[i]);
                    }

                    setForms(newForms);
                    setFormCount(resp.data.count);
                } else {
                    // TODO Error?
                }
                setFormNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                // TODO Error?
                setFormNetworkInFlight(false);
            });
    }

    if (!shown) return [];

    let clientContent = [];
    if (forms.length > 0) {
        for (let i = 0; i < forms.length; i++) {
            clientContent.push(
                <span className="list-group-item clickable" onClick={() => selectUser(forms[i])}>{forms[i].name}</span>
            )
        }
    }

    let loadMoreButton = [];

    if (formCount > forms.length) {
        loadMoreButton = (
            <div className={"row mt-4"}>
                <div className={"col-12 text-center"}>
                    <button className={"btn btn-primary"} onClick={() => setCurrentPage(currentPage + 1)}>{Rosetta.string("common.load_more")}</button>
                </div>
            </div>
        );
    }

    if (formNetworkInFlight) {
        loadMoreButton = [];

        for (let i = 0; i < 3; i++) {
            clientContent.push(
                <span className="list-group-item">
                    <PlaceholderText />
                </span>
            )
        }
    }

    if (clientContent.length > 0) {
        clientContent = (
            <div className="list-group">
                {clientContent}
            </div>
        );
    } else {
        clientContent = (
            <div className={"empty-message"}>{Rosetta.string("common.empty_message")}</div>
        );
    }

    return (
        <Offcanvas
            title={Rosetta.string("clients.list_title")}
            shown={true}
            gravity={(gravity !== undefined) ? gravity : OffcanvasGravity.END}
            forceDismiss={forceDismiss}
            callback={handleCallback}>

            <div className={"row"}>
                <div className={"col-12"}>
                    <FilterText callback={setKeyword} />
                </div>
            </div>

            <div className={"row mt-4"}>
                <div className={"col-12"}>
                    {clientContent}
                </div>
            </div>

            {loadMoreButton}

        </Offcanvas>
    )

}