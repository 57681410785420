import BaseComponent from "../../../../../BaseComponent";

import "./FormInputLabelComponent.css";

export default class FormInputLabelComponent extends BaseComponent {

    render() {
        let descriptionElem = [];
        if (this.props.input.description !== undefined) {
            descriptionElem = (<span className={"input-label"}>{this.props.input.description}</span>);
        }

        return (
            <div className={"form-input-label-component"}>
                <span className={"input-label"}><strong>{(this.props.input !== undefined) ? this.props.input.label : ""}</strong></span>
                {descriptionElem}
            </div>
        )
    }

}