import {ScreenTitle} from "../../../screenTitle/ScreenTitle";
import Rosetta from "../../../../../rosetta/Rosetta";
import {CommonUtil} from "../../../../../util/CommonUtil";
import {FilterText} from "../../../../filter/FilterText";
import TableComponent, {Column, TablePaginator} from "../../../../table/TableComponent";
import {ClientSelectionModal} from "../../../common/ClientSelectionModal";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {OffcanvasActions} from "../../../../offcanvas/Offcanvas";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../../network/API";
import {Toast} from "../../../../toast/TokyoToaster";
import {Navigator} from "../../../../../util/Navigator";
import {DocumentSectionEditorModal} from "./DocumentSectionEditorModal";
import {ProjectDocumentEditorModal} from "./ProjectDocumentEditorModal";

export const DocumentRequestSectionScreen = (props) => {

    const {sectionID} = useParams();

    const [section, setSection] = useState(null);
    const [sections, setSections] = useState([]);
    const [sectionsCount, setSectionsCount] = useState(0);
    const [sectionsNetworkInFlight, setSectionsNetworkInFlight] = useState(false);

    const [documents, setDocuments] = useState([]);
    const [documentCount, setDocumentCount] = useState(0);
    const [documentNetworkInFlight, setDocumentNetworkInFlight] = useState(false);

    const [sectionPage, setSectionPage] = useState(1);
    const [documentPage, setDocumentPage] = useState(1);

    const [filterClient, setFilterClient] = useState(null);
    const [filterSectionKeyword, setFilterSectionKeyword] = useState("");
    const [filterDocumentKeyword, setFilterDocumentKeyword] = useState("");

    const [clientSelectorShown, setClientSelectorShown] = useState(false);

    const [sectionEditorShown, setSectionEditorShown] = useState(false);
    const [sectionEditorSectionID, setSectionEditorSectionID] = useState(null);

    const [documentEditorShown, setDocumentEditorShown] = useState(false);
    const [documentEditorDocumentID, setDocumentEditorDocumentID] = useState(null);

    useEffect(() => {
        fetchSectionsFromNetwork();
        if (sectionID) {
            fetchDocumentsFromNetwork();
        }
    }, []);

    useEffect(() => {
        if (sectionPage > 1) {
            setSectionPage(1);
        } else {
            fetchSectionsFromNetwork();
        }

        if (sectionID) {
            if (documentPage > 1) {
                setDocumentPage(1);
            } else {
                fetchDocumentsFromNetwork();
            }
        }
    }, [sectionID, filterClient])

    useEffect(() => {
        fetchSectionsFromNetwork();
    }, [sectionPage, filterSectionKeyword])

    useEffect(() => {
        if (sectionID) {
            fetchDocumentsFromNetwork();
        }
    }, [documentPage, filterDocumentKeyword])

    function moveToSection(id) {
        if (id) {
            Navigator.navigate("/content-config/document-requests/section/" + id);
        }
    }

    function showSectionEditor(id) {
        setSectionEditorSectionID(id);
        setSectionEditorShown(true);
    }

    function showDocumentEditor(id) {
        setDocumentEditorDocumentID(id);
        setDocumentEditorShown(true);
    }

    function tableRowWasClicked(row) {
        showDocumentEditor(row.id);
    }

    function clientSelectorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                setFilterClient(data);
            }
            setClientSelectorShown(false);
        }
    }

    function sectionEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                fetchSectionsFromNetwork();
            }
            setSectionEditorShown(false);
        }
    }

    function documentEditorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                fetchDocumentsFromNetwork();
            }
            setDocumentEditorShown(false);
        }
    }

    function fetchSectionsFromNetwork() {
        if (sectionsNetworkInFlight) return;
        setSectionsNetworkInFlight(true);

        let data = {
            parentSectionID : sectionID,
            page : sectionPage
        };

        if (filterClient !== null) {
            data.clientID = filterClient.id;
        }

        if (filterSectionKeyword && filterSectionKeyword !== "") {
            data.keyword = filterSectionKeyword;
        }

        Axios.post(ENDPOINTS.project.getProjectDocumentSections, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSections(resp.data.data);
                    setSectionsCount(resp.data.count);

                    if (resp.data.section !== undefined) {
                        setSection(resp.data.section);
                    }
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    )
                }
                setSectionsNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSectionsNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    Rosetta.string("common.error_common_unknown", { error_code : "PDSA1000C" }),
                    Toast.ERROR,
                    Toast.LONG
                )
            })
    }

    function fetchDocumentsFromNetwork() {
        if (documentNetworkInFlight) return;
        setDocumentNetworkInFlight(true);

        let data = {
            sectionID,
            page : documentPage
        };

        if (filterClient !== null) {
            data.clientID = filterClient.id;
        }

        if (filterDocumentKeyword && filterDocumentKeyword !== "") {
            data.keyword = filterDocumentKeyword;
        }

        Axios.post(ENDPOINTS.project.getProjectDocumentsAdmin, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setDocuments(resp.data.data);
                    setDocumentCount(resp.data.count);
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    )
                }
                setDocumentNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setDocumentNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    Rosetta.string("common.error_common_unknown", { error_code : "PDDA1000C" }),
                    Toast.ERROR,
                    Toast.LONG
                )
            })
    }

    let clientClear = [];
    if (filterClient !== null) {
        clientClear = (
            <span className={"badge bg-secondary clickable"} onClick={() => setFilterClient(null)}>{Rosetta.string("common.clear")}</span>
        )
    }

    let documentTable = [];
    if (sectionID) {
        documentTable = (
            <>
                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <h3>{Rosetta.string("document_requests.documents_title")}</h3>
                    </div>
                </div>

                <div className={"row mt-1"}>
                    <div className={"col-12 col-md-4"}>
                        <FilterText callback={setFilterDocumentKeyword} />
                    </div>

                    <div className={"col-1 col-md-4"} />

                    <div className={"col-11 col-md-4 text-end"}>
                        <button className={"btn btn-primary"} onClick={() => showDocumentEditor()}>{Rosetta.string("document_requests.new_document")}</button>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                data={documents}
                                placeholderMode={documentNetworkInFlight}
                                placeholderRows={20}
                                onRowClick={tableRowWasClicked}>

                                <Column name={"title"} title={Rosetta.string("document_requests.document_title")} />
                                <Column name={"version"} title={Rosetta.string("document_requests.document_version")} />
                                <Column name={"id"} className={"text-end"} title={""} render={(data, row) => (
                                    <button className={"btn btn-primary"}>{Rosetta.string("common.edit")}</button>
                                )} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container mt-1"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={documentPage}
                                    totalCount={documentCount}
                                    pageSize={20}
                                    onClick={setDocumentPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    let clientInput = [];
    if (!section) {
        clientInput = (
            <div className={"row mt-4"}>
                <div className={"col-12 col-md-4"}>
                    <label>{Rosetta.string("projectSubmittable.filter_client")} {clientClear}</label>
                    <div className={"form-control clickable"} onClick={() => setClientSelectorShown(true)}>
                        {CommonUtil.getOrDefault(filterClient, "company_name", Rosetta.string("projectSubmittable.filter_client_all"))}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={"app-screen form-submittables-list-screen"}>

            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={section ? section.title : Rosetta.string("projectSubmittable.title")} />
                </div>
            </div>

            <div className={"animate-screen-content"}>
                {clientInput}

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <h3>{Rosetta.string("document_requests.sections_title")}</h3>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col-12 col-md-4"}>
                        <FilterText callback={setFilterSectionKeyword} />
                    </div>

                    <div className={"col-1 col-md-4"} />

                    <div className={"col-11 col-md-4 text-end"}>
                        <button className={"btn btn-primary"} onClick={() => showSectionEditor()}>{Rosetta.string("document_requests.new_section")}</button>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                data={sections}
                                placeholderMode={sectionsNetworkInFlight}
                                placeholderRows={20}>

                                <Column name={"clientName"} title={Rosetta.string("document_requests.section_client")} />
                                <Column name={"title"} title={Rosetta.string("document_requests.section_title")} />
                                <Column name={"id"} className={"text-end"} title={""} render={(data, row) => (
                                    <>
                                        <button className={"btn btn-primary"} onClick={() => moveToSection(data)}>{Rosetta.string("common.view")}</button>
                                        &nbsp;
                                        <button className={"btn btn-primary"} onClick={() => showSectionEditor(data)}>{Rosetta.string("common.edit")}</button>
                                    </>
                                )} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container mt-1"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={sectionPage}
                                    totalCount={sectionsCount}
                                    pageSize={20}
                                    onClick={setSectionPage} />
                            </div>
                        </div>
                    </div>
                </div>

                {documentTable}
            </div>

            <ClientSelectionModal
                shown={clientSelectorShown}
                callback={clientSelectorDidCallback} />

            <DocumentSectionEditorModal
                shown={sectionEditorShown}
                clientID={section ? section.clientID : undefined}
                sectionID={sectionEditorSectionID}
                parentSectionID={sectionID}
                callback={sectionEditorDidCallback} />

            <ProjectDocumentEditorModal
                shown={documentEditorShown}
                clientID={section ? section.clientID : undefined}
                documentID={documentEditorDocumentID}
                sectionID={sectionID}
                callback={documentEditorDidCallback} />

        </div>
    );

}