import {Offcanvas, OffcanvasActions, OffcanvasGravity} from "../../../offcanvas/Offcanvas";
import Rosetta from "../../../../rosetta/Rosetta";
import {useEffect, useState} from "react";
import {AppUser} from "../../../../util/AppUser";
import {ClientSelectionModal} from "../../common/ClientSelectionModal";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {Toast} from "../../../toast/TokyoToaster";
import Validator from "../../../../util/Validator";
import AlertModal from "../../../alertmodal/AlertModal";
import WindowUtil from "../../../../util/WindowUtil";

export const AdminUserEditorModal = (props) => {

    const {shown} = props;
    const {userID} = props;
    const {callback} = props;

    const [user, setUser] = useState();
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userRoleID, setUserRoleID] = useState(AppUser.roles.SYSTEM_ADMIN);
    const [userEmailAddress, setUserEmailAddress] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userPasswordRetype, setUserPasswordRetype] = useState("");
    const [userActive, setUserActive] = useState(1);

    const [userClient, setUserClient] = useState(null);

    const [callbackData, setCallbackData] = useState(null);
    const [clientSelectorShown, setClientSelectorShown] = useState(false);

    const [forceDismiss, setForceDismiss] = useState(false);

    const [networkInFlight, setNetworkInFlight] = useState(false);

    useEffect(() => {
        if (shown) {
            if (userID) {
                fetchUserFromNetwork();
            }
            WindowUtil.lockBodyScroll();
        } else {
            WindowUtil.unlockBodyScroll();

            setUser(null);
            setUserFirstName("");
            setUserLastName("");
            setUserClient(null);
            setUserRoleID(AppUser.roles.SYSTEM_ADMIN);
            setUserEmailAddress("");
            setUserPassword("");
            setUserPasswordRetype("");
            setUserActive("1");

            setForceDismiss(false);
        }
    }, [shown]);

    useEffect(() => {
        if (!clientSelectorShown && shown) {
            WindowUtil.lockBodyScroll();
        }
    }, [clientSelectorShown]);

    useEffect(() => {
        if (user) {
            setUserFirstName(user.firstName);
            setUserLastName(user.surname);
            setUserRoleID(user.userRoleID);
            setUserEmailAddress(user.emailAddress);
            setUserActive(user.active);

            setUserClient({ id : user.clientID, company_name : user.clientName });
        }
    }, [user])

    function handleCallback(action) {
        if (callback) {
            callback(action, callbackData);
        }
    }

    function clientSelectorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                setUserClient(data);
            }
            setClientSelectorShown(false);
        }
    }

    function fetchUserFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let data = {
            ids : [userID],
            page : 1,
            pageSize : 1
        };

        Axios.post(ENDPOINTS.user.getAdminUsers, data)
            .then((r) => {
                let error = null;

                const resp = API.parse(r);
                if (resp.success) {
                    if (resp.data.data.length > 0) {
                        setUser(resp.data.data[0]);
                    } else {
                        error = Rosetta.string("admin_users.editor_not_found");
                    }
                } else {
                    error = API.formatError(resp);
                }

                if (error !== null) {
                    Toast.show(
                        Rosetta.string("common.error"),
                        error,
                        Toast.ERROR,
                        Toast.LONG
                    );
                    setForceDismiss(true);
                }

                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    Rosetta.string("common.error_common_unknown", { error_code : "AUE1000C" }),
                    Toast.ERROR,
                    Toast.LONG
                );
            });
    }

    function submitUserOverNetwork() {
        if (networkInFlight) return;

        const validationResult = Validator.validateCreateFormData({
            userID, clientID : userClient ? userClient.id : undefined,
            userFirstName, userLastName, userRoleID,
            userEmailAddress, userPassword, userPasswordRetype,
            userActive
        }, [
            Validator.rule("userID", "int", "", "id", true),
            Validator.rule("clientID", "int", Rosetta.string("admin_users.editor_client"), "clientID"),
            Validator.rule("userFirstName", "string", Rosetta.string("admin_users.editor_first_name"), "firstName"),
            Validator.rule("userLastName", "string", Rosetta.string("admin_users.editor_last_name"), "surname"),
            Validator.rule("userRoleID", "int", Rosetta.string("admin_users.editor_user_role"), "userRoleId"),
            Validator.rule("userEmailAddress", "string", Rosetta.string("admin_users.editor_email_address"), "emailAddress"),
            Validator.rule("userPassword", "string", Rosetta.string("admin_users.editor_password_default"), "password", user !== null),
            Validator.rule("userActive", "int", Rosetta.string("admin_users.editor_active"), "active", true)
        ]);

        if (!validationResult.success) {
            AlertModal.showError(validationResult.error);
            return;
        }

        const formData = validationResult.formData;

        if (userPassword !== "") {
            if (userPassword === userPasswordRetype) {
                formData.append("password", userPassword);
            } else {
                AlertModal.showError(Rosetta.string("validation.missing_property", { label : Rosetta.string("subscription.success_error_password_mismatch")}));
                return;
            }
        }

        setNetworkInFlight(true);

        Axios.post(ENDPOINTS.user.submitUser, formData)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    Toast.show(
                        Rosetta.string("common.success"),
                        Rosetta.string("admin_users.editor_submit_success"),
                        Toast.SUCCESS,
                        Toast.LONG
                    );

                    setCallbackData(resp.data.user);
                    setForceDismiss(true);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "AUE1000C" }));
            })
    }

    if (!shown) return [];

    return (
        <>
            <Offcanvas
                shown={true}
                title={Rosetta.string("admin_users.editor_title")}
                forceDismiss={forceDismiss}
                gravity={OffcanvasGravity.END}
                callback={handleCallback}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("admin_users.editor_first_name")}</label>
                        <input type={"text"} className={"form-control"} value={userFirstName} onChange={(e) => setUserFirstName(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("admin_users.editor_last_name")}</label>
                        <input type={"text"} className={"form-control"} value={userLastName} onChange={(e) => setUserLastName(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("admin_users.editor_email_address")}</label>
                        <input type={"text"} className={"form-control"} value={userEmailAddress} onChange={(e) => setUserEmailAddress(e.target.value)} />
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("admin_users.editor_client")}</label>
                        <div className={"alert bg-warning mb-1"}>{Rosetta.string("admin_users.editor_client_explain")}</div>
                        <span className={"form-control clickable"} onClick={() => setClientSelectorShown(true)}>{ userClient ? userClient.company_name : Rosetta.string("common.please_select") }</span>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <div className={"card"}>
                            <div className={"card-body"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <label>{Rosetta.string("admin_users." + (user ? "editor_password" : "editor_password_default"))}</label>
                                        <input type={"password"} className={"form-control"} value={userPassword} onChange={(e) => setUserPassword(e.target.value)} />
                                    </div>

                                    <div className={"col-12 mt-2"}>
                                        <label>{Rosetta.string("admin_users.editor_password_retype")}</label>
                                        <input type={"password"} className={"form-control"} value={userPasswordRetype} onChange={(e) => setUserPasswordRetype(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className={"col-12"}>
                        <label>{Rosetta.string("admin_users.editor_active")}</label>
                        <select className={"form-select"} value={userActive} onChange={(e) => setUserActive(e.target.value)}>
                            <option value={"0"}>{Rosetta.string("admin_users.editor_active_no")}</option>
                            <option value={"1"}>{Rosetta.string("common.yes")}</option>
                        </select>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        <button className={"btn btn-primary"} onClick={() => submitUserOverNetwork()}>{Rosetta.string("common.save")}</button>
                    </div>
                </div>

            </Offcanvas>

            <ClientSelectionModal
                shown={clientSelectorShown}
                callback={clientSelectorDidCallback} />
        </>

    )

}