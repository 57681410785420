import BaseComponent from "../../../../../BaseComponent";

import "./FormInputDateTimeComponent.css";

import dateIcon from "../../../../../../assets/images/calendar.svg";
import Rosetta from "../../../../../../rosetta/Rosetta";

export default class FormInputDateTimeComponent extends BaseComponent {

    render() {
        let label = "";
        let prefix = [];
        let suffix = [];

        if (this.props.input !== undefined) {
            label = this.props.input.label;

            if (this.props.input.prefixLabel !== undefined && this.props.input.prefixLabel !== "") {
                prefix = (<div className={"in-label prefix"}>{this.props.input.prefixLabel}</div>);
            }

            if (this.props.input.suffixLabel !== undefined && this.props.input.suffixLabel !== "") {
                suffix = (<div className={"in-label suffix"}>{this.props.input.suffixLabel}</div>);
            }
        }

        return (
            <div className={"form-input-date-time-component"}>
                <div className={"label"}>{label}</div>
                <div className={"date-content"}>
                    {prefix}
                    <div className={"date-contain"}>
                        <div className={"icon"} style={{backgroundImage : "url(" + dateIcon + ")"}} />
                        <div className={"text"}>{Rosetta.string("form_input.date_label")}</div>
                    </div>
                    {suffix}
                </div>
            </div>
        )
    }

}