import BaseComponent from "../../../../../BaseComponent";

import "./FormInputMultiSelectComponent.css";
import Rosetta from "../../../../../../rosetta/Rosetta";
import {Form} from "../../../../../../util/Form";

export default class FormInputMultiSelectComponent extends BaseComponent {

    render() {
        let label = "";
        let options = [];

        if (this.props.input !== undefined) {
            label = this.props.input.label;
            options = this.props.input.options;
        }

        if (options === undefined || options === null) {
            options = [];
        }

        if (options.length === 0) {
            let inputDataTypeId = parseInt(this.props.input.dataTypeID);

            options = [
                {
                    label : Rosetta.string("common.yes"),
                    value : "1",
                },
                {
                    label : Rosetta.string("common.no"),
                    value : "0",
                }
            ];

            if (inputDataTypeId === Form.DATA_TYPE.TRINARY) {
                options.push({
                    label : Rosetta.string("common.na"),
                    value : "2",
                });
            }
        }

        return (
            <div className={"form-input-multi-select-component"}>
                <div className={"label"}>{label}</div>
                {
                    options.map((option) => (
                        <div className={"option"}>{option.label}</div>
                    ))
                }
            </div>
        )
    }

}