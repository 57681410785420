import BaseComponent from "../../../../BaseComponent";
import Rosetta from "../../../../../rosetta/Rosetta";
import { v4 as uuidv4 } from "uuid";

import arrowUp from "../../../../../assets/images/arrow_up.svg";
import arrowDown from "../../../../../assets/images/arrow_down.svg";
import trash from "../../../../../assets/images/delete.svg";
import WindowUtil from "../../../../../util/WindowUtil";
import AlertModal from "../../../../alertmodal/AlertModal";
import ArrayUtil from "../../../../../util/ArrayUtil";
import {Form} from "../../../../../util/Form";

export default class FormQuestionInputEditor extends BaseComponent {

    dismissTimeout = undefined;

    constructor(props, context) {
        super(props, context);

        this.initState({
            isDismissing : false,
            input : undefined
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.shown !== this.props.shown) {
            if (this.props.shown) {
                WindowUtil.lockBodyScroll();

                let input = this.props.input;
                if (input === undefined) {
                    input = {
                        dataKey : "",
                        label : "",
                        dataTypeID : Form.DATA_TYPE.LABEL,
                        mandatory : 0
                    };
                }

                this.setState({
                    isDismissing: false,
                    input
                });
            } else {
                WindowUtil.unlockBodyScroll();
            }
        }
    }

    dismissWasRequested = (success) => {
        if (this.state.isDismissing) return;

        this.setState({
            isDismissing : true
        });

        clearTimeout(this.dismissTimeout);
        this.dismissTimeout = setTimeout(() => {
            if (this.props.callback !== undefined) {
                let out = undefined;
                if (success) {
                    out = this.state.input;
                }

                this.props.callback(out);
            }
        }, 200);
    }

    showError = (errorMessage) => {
        AlertModal.showModal(
            Rosetta.string("common.error"),
            errorMessage
        );
    }

    submitForm = () => {
        let input = this.state.input;

        if (input.label === "") {
            this.showError(Rosetta.string("form_input.editor_validation_error", {label : Rosetta.string("form_editor.input_label")}));
            return false;
        }

        if (input.dataKey === "") {
            this.showError(Rosetta.string("form_input.editor_validation_error", {label : Rosetta.string("form_editor.input_data_key")}));
            return false;
        }

        if (this.shouldTypeShowOptions(this.state.questionDataTypeID)) {
            if (input.options !== undefined) {
                for (let i = 0; i < input.options.length; i++) {
                    let option = input.options[i];

                    if (option.label === "") {
                        this.showError(Rosetta.string("form_input.editor_validation_error_verbose", {
                            label: Rosetta.string("form_editor.option_label"),
                            target: Rosetta.string("form_input.editor_option_header", {number: (i + 1)})
                        }));
                        return false;
                    }

                    if (option.value === "") {
                        this.showError(Rosetta.string("form_input.editor_validation_error_verbose", {
                            label: Rosetta.string("form_editor.option_value"),
                            target: Rosetta.string("form_input.editor_option_header", {number: (i + 1)})
                        }));
                        return false;
                    }
                }
            }
        }

        this.dismissWasRequested(true);
        return true;
    }

    getInputProperty = (keyName, defaultValue) => {
        if (defaultValue === undefined) {
            defaultValue = null;
        }

        if (this.state.input !== undefined && this.state.input !== null) {
            if (this.state.input[keyName] !== undefined && this.state.input[keyName] !== null) {
                return this.state.input[keyName];
            }
        }
        return defaultValue;
    }

    setInputProperty = (keyName, value) => {
        let input = this.state.input;
        if (input === undefined || input === null) {
            input = {};
        }
        input[keyName] = value;

        this.setState({
            input
        });
    }

    /*
     * OPTIONS
     */

    addOption = (label, value) => {
        if (label === undefined) {
            label = "";
        }

        if (value === undefined) {
            value = "";
        }

        let input = this.state.input;
        if (input === undefined || input === null) {
            input = {};
        }

        if (input.options === undefined) {
            input.options = [];
        }

        input.options.push({
            localId : uuidv4(),
            label : label,
            value : value,
            active : 1
        });

        this.setState({
            input
        });
    }

    addYesNoOptions = () => {
        this.addOption("Yes", 1);
        this.addOption("No", 0);
    }

    getOptionValue = (localId, keyName, defaultValue) => {
        if (defaultValue === undefined) {
            defaultValue = null;
        }

        if (this.state.input !== undefined && this.state.input !== null) {
            if (this.state.input.options !== undefined && this.state.input.options !== null) {
                for (let i = 0; i < this.state.input.options.length; i++) {
                    if (this.state.input.options[i].localId === localId) {
                        if (this.state.input.options[i][keyName] !== undefined) {
                            return this.state.input.options[i][keyName];
                        }
                        break;
                    }
                }
            }
        }

        return defaultValue;
    }

    setOptionValue = (localId, keyName, value) => {
        let input = this.state.input;
        if (input !== undefined && input !== null) {
            if (input.options !== undefined && input.options !== null) {
                for (let i = 0; i < input.options.length; i++) {
                    if (input.options[i].localId === localId) {
                        input.options[i][keyName] = value;
                        break;
                    }
                }
            }
        }

        this.setState({
            input
        });
    }

    moveOption = (index, direction) => {
        let input = this.state.input;

        if (input.options !== undefined) {
            let newIndex = index + direction;

            if (newIndex < 0 || newIndex > (input.options.length - 1)) {
                return;
            }

            input.options = ArrayUtil.arrayMoveImmutable(input.options, index, newIndex);
        }

        this.setState({
            input
        });
    }

    removeOption = (index) => {
        let input = this.state.input;

        if (input.options !== undefined) {
            if (input.options[index].id !== undefined) {
                if (input.deletedOptionIds === undefined) {
                    input.deletedOptionIds = [];
                }
                input.deletedOptionIds.push(input.options[index].id);
            }

            input.options.splice(index, 1);
        }

        this.setState({
            input
        });
    }

    shouldTypeShowOptions = (selectedDataType) => {
        return selectedDataType === Form.DATA_TYPE.DROPDOWN
            || selectedDataType === Form.DATA_TYPE.RADIO;
    }

    render() {
        if (!this.props.shown) return [];

        // We mutate key here to "recreate" the element in the DOM to allow us to replay the entry animation on exit
        let elemKey = "questioninputslide_one";
        let dismissClass = "";
        if (this.state.isDismissing) {
            dismissClass = " dismissing";
            elemKey = "questioninputslide_two";
        }

        let formContent = [];

        let optionItems = [];
        let additionalAttributeElements = [];
        let optionButtons = [];

        if (this.state.input !== undefined) {
            if (this.state.input.hasOwnProperty("options")) {
                if (this.state.input.options.length > 0) {
                    this.state.input.options.forEach((option, index) => {
                        optionItems.push(
                            <div className={"card mt-2"}>
                                <div className={"card-header card-header-flex"}>
                                    <div className={"editor_flex"}>
                                        <div className={"flex-title fill-space"}>
                                            {Rosetta.string("form_input.editor_option_header", {number: (index + 1)})}
                                        </div>
                                        <div className={"actions"}>
                                            <div className={"action-item"} style={{backgroundImage : "url(" + arrowUp + ")"}} onClick={() => this.moveOption(index, -1)} />
                                            <div className={"action-item"} style={{backgroundImage : "url(" + arrowDown + ")"}} onClick={() => this.moveOption(index, 1)} />
                                            <div className={"action-item"} style={{backgroundImage : "url(" + trash + ")"}} onClick={() => this.removeOption(index)} />
                                        </div>
                                    </div>
                                </div>

                                <div className={"card-body"}>
                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("form_editor.option_label")}</label>
                                            <input type={"text"} className={"form-control"} name={"label"}
                                                   value={option.label}
                                                   onChange={(e) => this.setOptionValue(option.localId, e.target.name, e.target.value)}/>
                                        </div>

                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("form_editor.option_value")}</label>
                                            <input type={"text"} className={"form-control"} name={"value"}
                                                   value={option.value}
                                                   onChange={(e) => this.setOptionValue(option.localId, e.target.name, e.target.value)}/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    });

                    optionButtons = (
                        <div className={"col-12 mt-2"}>
                            <div className={"col-12 text-center mt-2"}>
                                <span className={"btn btn-secondary"} onClick={() => this.addOption()}>{Rosetta.string("form_editor.input_add_option")}</span>
                            </div>
                        </div>
                    );
                }
            }

            let selectedDataType = parseInt(this.state.input.dataTypeID);

            if (selectedDataType === Form.DATA_TYPE.LABEL) {
                additionalAttributeElements.push(
                    <div className={"col-12"}>
                        <label>{Rosetta.string("form_editor.input_label_description")}</label>
                        <input type={"text"} className={"form-control"} name={"description"}
                               value={this.state.input.description}
                               onChange={(e) => this.setInputProperty(e.target.name, e.target.value)}/>
                    </div>
                );
            }

            if (selectedDataType === Form.DATA_TYPE.TEXT) {
                additionalAttributeElements.push(
                    <div className={"col-12"}>
                        <label>{Rosetta.string("form_editor.input_multiline")}</label>
                        <select className={"form-select"} name={"multiline"} value={this.state.input.multiline}
                                onChange={(e) => this.setInputProperty(e.target.name, e.target.value)}>
                            <option value={0}>{Rosetta.string("common.no")}</option>
                            <option value={1}>{Rosetta.string("common.yes")}</option>
                        </select>
                    </div>
                )
            }

            if (selectedDataType === Form.DATA_TYPE.DATE_TIME) {
                additionalAttributeElements.push(
                    <div className={"col-12"}>
                        <label>{Rosetta.string("form_editor.input_date_mode")}</label>
                        <select className={"form-select"} name={"dateMode"} value={this.state.input.dateMode}
                                onChange={(e) => this.setInputProperty(e.target.name, e.target.value)}>
                            <option
                                value={"DATE_TIME"}>{Rosetta.string("form_editor.input_date_mode_datetime")}</option>
                            <option value={"DATE"}>{Rosetta.string("form_editor.input_date_mode_date")}</option>
                            <option value={"TIME"}>{Rosetta.string("form_editor.input_date_mode_time")}</option>
                        </select>
                    </div>
                )

                additionalAttributeElements.push(
                    <div className={"col-12"}>
                        <label>{Rosetta.string("form_editor.input_date_format")}</label>
                        <input type={"text"} className={"form-control"} name={"dateFormat"}
                               value={this.state.input.dateFormat}
                               placeholder={Rosetta.string("form_editor.input_date_format_default")}
                               onChange={(e) => this.setInputProperty(e.target.name, e.target.value)}/>
                    </div>
                )
            }

            if (additionalAttributeElements.length > 0) {
                additionalAttributeElements = (
                    <div className={"row"}>
                        {additionalAttributeElements}
                    </div>
                );
            }

            let optionFields = [];
            if (this.shouldTypeShowOptions(selectedDataType)) {
                optionFields = (
                    <>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <label>{Rosetta.string("form_editor.mandatory")}</label>
                                <select className={"form-select"} name={"mandatory"} value={this.state.input.mandatory} onChange={(e) => this.setInputProperty(e.target.name, e.target.value)}>
                                    <option value={"0"}>{Rosetta.string("common.no")}</option>
                                    <option value={"1"}>{Rosetta.string("common.yes")}</option>
                                </select>
                            </div>
                        </div>

                        <div className={"row mt-4"}>
                            <div className={"col-12"}>
                                <div className={"editor_flex"}>
                                    <h3 className={"fill-space"}>{Rosetta.string("form_editor.input_options")}</h3>
                                    <div>
                                        <span className={"badge bg-secondary"} onClick={() => this.addOption()}>{Rosetta.string("form_editor.input_add_option")}</span>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-12"}>
                                {optionItems}
                            </div>

                            {optionButtons}
                        </div>
                    </>
                );
            }

            formContent = (
                <div>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <label>{Rosetta.string("form_editor.input_data_type")}</label>
                            <select className={"form-select"} name={"dataTypeID"} value={parseInt(this.state.input.dataTypeID)} onChange={(e) => this.setInputProperty(e.target.name, e.target.value)}>
                                {
                                    Form.DATA_TYPES().map((dataType) => (
                                        <option value={dataType.id}>{dataType.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12"}>
                            <label>{Rosetta.string("form_editor.input_data_key")}</label>
                            <input type={"text"} className={"form-control"} name={"dataKey"} value={this.state.input.dataKey} onChange={(e) => this.setInputProperty(e.target.name, e.target.value)} />
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col-12"}>
                            <label>{Rosetta.string("form_editor.input_label")}</label>
                            <input type={"text"} className={"form-control"} name={"label"} value={this.state.input.label} onChange={(e) => this.setInputProperty(e.target.name, e.target.value)} />
                        </div>
                    </div>

                    {additionalAttributeElements}

                    {optionFields}

                </div>
            )
        }

        return (
            <div className={"account-side-on-component" + dismissClass} key={elemKey}>
                <div className={"offcanvas-scrim"} />

                <div className="offcanvas offcanvas-end">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title">{Rosetta.string("form_input.editor_title")}</h5>
                        <button type="button"
                                className="btn-close text-reset"
                                aria-label={Rosetta.string("common.close")}
                                onClick={() => this.dismissWasRequested(false)} />
                    </div>
                    <div className="offcanvas-body">
                        {formContent}
                    </div>
                    <div className={"offcanvas-footer text-end"}>
                        <button className={"btn btn-success"} onClick={() => this.submitForm()}>{Rosetta.string("common.save")}</button>
                    </div>
                </div>
            </div>

        )
    }

    /*
    <div className={"col-12 text-center mt-2"}>
                                <span className={"btn btn-outline-secondary"} onClick={() => this.addYesNoOptions()}>Add Yes/No Options</span>
                            </div>
     */

}