import BaseComponent from "../../../../../BaseComponent";

import "./FormInputRadioComponent.css";
import Rosetta from "../../../../../../rosetta/Rosetta";

export default class FormInputRadioComponent extends BaseComponent {


    render() {
        let label = "";
        let options = [];
        let hasDataSouce = false;

        let prefix = [];
        let suffix = [];

        if (this.props.input !== undefined) {
            label = this.props.input.label;
            options = this.props.input.options;
            hasDataSouce = this.props.input.dataSource !== undefined && this.props.input.dataSource !== null;

            if (this.props.input.prefixLabel !== undefined && this.props.input.prefixLabel !== "") {
                prefix = (<div className={"in-label prefix"}>{this.props.input.prefixLabel}</div>);
            }

            if (this.props.input.suffixLabel !== undefined && this.props.input.suffixLabel !== "") {
                suffix = (<div className={"in-label suffix"}>{this.props.input.suffixLabel}</div>);
            }
        }

        if (options === undefined || options === null) {
            options = [];
        }

        if (options.length === 0 && hasDataSouce) {
            for (let i = 1; i <= 5; i++) {
                options.push({
                    label : Rosetta.string("form_input.data_source_filler", {number: i}),
                    value : i,
                });
            }
        }

        return (
            <div className={"form-input-radio-component"}>
                <div className={"label"}>{label}</div>
                <div className={"radio-content"}>
                    {prefix}
                    <form className={"radio-group"}>
                        {
                            options.map((option, index) => (
                                <label><input type={"radio"} checked={index === 0} /> {option.label}</label>
                            ))
                        }
                    </form>
                    {suffix}
                </div>

            </div>
        )
    }

}