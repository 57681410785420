import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../../rosetta/Rosetta";

import "./ContentConfigLandingScreen.css";
import {Navigator} from "../../../../util/Navigator";

export const ContentConfigLandingScreen = (props) => {

    const options = [
        {
            title : Rosetta.string("content_config.category_document_requests"),
            subtitle : Rosetta.string("content_config.category_document_requests_subtitle"),
            actionLabel : Rosetta.string("common.view"),
            onClick : () => {
                Navigator.navigate("/content-config/document-requests/");
            }
        },
        {
            title : Rosetta.string("content_config.category_forms"),
            subtitle : Rosetta.string("content_config.category_forms_subtitle"),
            actionLabel : Rosetta.string("common.view"),
            onClick : () => {
                Navigator.navigate("/content-config/forms");
            }
        },
        {
            title : Rosetta.string("content_config.category_document_management"),
            subtitle : Rosetta.string("content_config.category_document_management_subtitle"),
            actionLabel : Rosetta.string("common.view"),
            onClick : () => {
                Navigator.navigate("/content-config/collection-documents/");
            }
        },
    ]

    return (
        <div className={"app-screen content-config-landing-screen"}>

            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("content_config.title")} />
                </div>
            </div>

            <div className={"animate-screen-content"}>

                <div className={"row"}>

                    {
                        options.map((option) => (
                            <div className={"col-12 col-md-6 col-lg-4 mt-2"}>
                                <div className={"card option-card"} onClick={() => option.onClick()}>
                                    <div className={"card-body"}>
                                        <div className={"card-title"}><h4>{option.title}</h4></div>
                                        <div className={"card-subtitle"}>{option.subtitle}</div>
                                        <div className={"card-action"}>
                                            <button className={"btn btn-primary"}>{option.actionLabel}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>

            </div>

        </div>
    )

}