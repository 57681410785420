import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../../rosetta/Rosetta";
import {FilterText} from "../../../filter/FilterText";
import TableComponent, {Column, TablePaginator} from "../../../table/TableComponent";
import {useEffect, useState} from "react";
import {AppUser} from "../../../../util/AppUser";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import AlertModal from "../../../alertmodal/AlertModal";
import {AdminUserEditorModal} from "./AdminUserEditorModal";
import {OffcanvasActions} from "../../../offcanvas/Offcanvas";

export const AdminUserListScreen = (props) => {

    const [users, setUsers] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [networkInFlight, setNetworkInFlight] = useState(false);

    const [page, setPage] = useState(1);

    const [keyword, setKeyword] = useState("");

    const [editorShown, setEditorShown] = useState(false);
    const [editorUserID, setEditorUserID] = useState(null);

    useEffect(() => {
        fetchUsersFromNetwork();
    }, []);

    useEffect(() => {
        fetchUsersFromNetwork();
    }, [page, keyword])

    function showEditor(id) {
        setEditorUserID(id);
        setEditorShown(true);
    }

    function editorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                fetchUsersFromNetwork();
            }
            setEditorShown(false);
        }
    }

    function fetchUsersFromNetwork() {
        if (networkInFlight) return;
        setNetworkInFlight(true);

        let data = {
            userRoleIDs : [AppUser.roles.SYSTEM_ADMIN],
            keyword,
            page
        }

        Axios.post(ENDPOINTS.user.getAdminUsers, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setUsers(resp.data.data);
                    setTotalCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "AUL1000C" }));
            });
    }

    return (
        <div className={"app-screen document-collection-type-list-screen"}>

            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("admin_users.title")} />
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-4"}>
                        <FilterText callback={setKeyword} />
                    </div>

                    <div className={"col-1 col-md-4"} />

                    <div className={"col-11 col-md-4 text-end"}>
                        <button className={"btn btn-primary"} onClick={() => showEditor()}>{Rosetta.string("admin_users.new_user")}</button>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                data={users}
                                placeholderMode={networkInFlight}
                                placeholderRows={20}
                                onRowClick={(row) => showEditor(row.id)}>

                                <Column name={"firstName"} title={Rosetta.string("admin_users.table_first_name")} />
                                <Column name={"surname"} title={Rosetta.string("admin_users.table_last_name")} />
                                <Column name={"userRoleName"} title={Rosetta.string("admin_users.table_user_type")} />
                                <Column name={"emailAddress"} title={Rosetta.string("admin_users.table_email")} />
                                <Column name={"id"} className={"text-end"} render={(data, row) => (
                                    <button className={"btn btn-primary"}>{Rosetta.string("common.edit")}</button>
                                )} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container mt-1"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={page}
                                    totalCount={totalCount}
                                    pageSize={20}
                                    onClick={setPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AdminUserEditorModal
                shown={editorShown}
                userID={editorUserID}
                callback={editorDidCallback} />

        </div>
    );

}