import BaseComponent from "../../../../../BaseComponent";

import "./FormInputTextComponent.css";
import {Form} from "../../../../../../util/Form";

export default class FormInputTextComponent extends BaseComponent {

    render() {
        let label = "";
        let prefix = [];
        let suffix = [];

        let textboxClass = "large";

        if (this.props.input !== undefined) {
            let multiline = false;

            if (this.props.input.multiline !== undefined) {
                multiline = this.props.input.multiline === true || this.props.input.multiline === "true" || parseInt(this.props.input.multiline) === 1;
            }

            if (!multiline || parseInt(this.props.input.dataTypeID) === Form.DATA_TYPE.NUMBER) {
                textboxClass = "small";
            }

            label = this.props.input.label;

            if (this.props.input.prefixLabel !== undefined && this.props.input.prefixLabel !== "") {
                prefix = (<div className={"in-label prefix"}>{this.props.input.prefixLabel}</div>);
            }

            if (this.props.input.suffixLabel !== undefined && this.props.input.suffixLabel !== "") {
                suffix = (<div className={"in-label suffix"}>{this.props.input.suffixLabel}</div>);
            }
        }

        return (
            <div className={"form-input-text-component"}>
                <div className={"label"}>{label}</div>
                <div className={"textbox-content"}>
                    {prefix}
                    <div className={"textbox " + textboxClass} />
                    {suffix}
                </div>

            </div>
        )
    }

}